import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import Head from '../components/head'

import blogStyles from './blog.module.scss'


export const query = graphql`
    query (
        $slug: String!
    ) {
        contentfulPost(
            slug: {
                eq: $slug
            }
        ) {
            title
            excerpt {
                excerpt
            }
            slug
            publishedData(formatString: "MMMM Do, YYYY")
            postTags
            postBody {
                json
            }
        }
    }
`

const Blog = (props) => {

    const markupData = [
        {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "headline" : props.data.contentfulPost.title,
            "description" : props.data.contentfulPost.excerpt['excerpt'],
            "datePublished" : props.data.contentfulPost.publishedData,
            "keywords" : props.data.contentfulPost.postTags,
            "url" : `https://www.nfeloapp.com/analysis/${props.data.contentfulPost.slug}/`,
            "author" : {
                "@context": "http://schema.org",
                "@type": "Person",
                "givenName" : "Robby Greer",
                "knowsAbout" : "Analytics",
                "sameAs" : [
                    "https://twitter.com/greerreNFL",
                    "https://www.linkedin.com/in/greerre/"
                ]
            }
        }
    ]

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {
                const alt = node.data.target.fields.title['en-US']
                const url = node.data.target.fields.file['en-US'].url
                return <img alt={alt} src={url} className={blogStyles.img_resize}/>
            },
            "paragraph": (node) => {
                return <p className={blogStyles.blogParagraph}>{
                    node.content.map(nodeContent => {
                        if (nodeContent.nodeType === 'hyperlink') {
                            return <a className={blogStyles.inLineLink} href={nodeContent.data.uri}>{nodeContent.content[0].value}</a>
                        } else {
                            return nodeContent.value
                        }
                    })
                }</p>
            },
            "heading-2": (node) => {
                return <h2 className={blogStyles.blogHeader}>{node.content[0].value}</h2>
            },
            "hyperlink": (node) => {
                return <a className={blogStyles.inLineLink} href={node.data.uri}>{node.content[0].value}</a>
            }
        }
    }
    return (
        <Layout>
            <Head
                title={props.data.contentfulPost.title}
                pageDescription={props.data.contentfulPost.excerpt.excerpt}
                schemaMarkup={markupData}
            />
            <div className={blogStyles.postContainer}>
                <div className={blogStyles.titleContainer}>
                    <h1>
                        {props.data.contentfulPost.title}
                    </h1>
                    <p>
                        {props.data.contentfulPost.publishedData}    {props.data.contentfulPost.postTags.map(tag => {
                            return (
                                <span className={blogStyles.tagSpan}>
                                    /  {tag}  /
                                </span>
                            )
                            })}
                    </p>
                </div>
                <div className={blogStyles.bodyContainer}>
                    {documentToReactComponents(props.data.contentfulPost.postBody.json, options)}
                </div>
            </div>
        </Layout>
    )
}

export default Blog

